<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper clearfix">
      <app-breadcrumb :breadcrumbs="breadcrumbs"/>
      <div class="content-detached content-right">
        <div class="content-wrapper">
          <div class="row">
            <div class="col-12 text-right">
              <router-link
                  :to="{ name: 'product-create' }"
                  class="btn btn-primary"
                  v-if="$can('create', 'products')"
              >
                <span class="text-nowrap">Добавить Товар</span>
              </router-link>
            </div>
          </div>
          <div class="body-content-overlay"/>
          <div class="ecommerce-searchbar mt-1">
            <div class="row">
              <div class="col-12">
                <b-input-group class="input-group-merge">
                  <b-form-input
                      v-model="filters.search"
                      placeholder="Поиск товара"
                      class="search-product"
                      @keyup.enter="getProducts"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                        icon="SearchIcon"
                        class="text-muted"
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
          </div>
          <!-- Products -->
          <section class="grid-view" v-if="!loading">
            <div
              v-for="product in products.data"
              :key="product.id"
              :class="['card ecommerce-card', product.user_id && 'user-product']"
            >
              <h6 class="item-name ml-1">
                <router-link class="text-body" :to="{ name: 'dictionary-product-details', params: { id: product.id } }">
                  {{ product.name }} {{ product.brand_name }}
                </router-link>
              </h6>
              <div class="row">
                <div class="col-12">
                  <div class="item-img text-center">
                    <span v-if="product.discount > 0" class="discount-block">-{{
                        product.discount | toPretty(0)
                      }}%</span>
                    <router-link :to="{ name: 'dictionary-product-details', params: { id: product.id } }">
                      <img
                          v-if="product.small_main_image"
                          :alt="`${product.name}-${product.id}`"
                          class="img-fluid"
                          :src="`img/product/${product.small_main_image}`"
                      />
                    </router-link>
                  </div>
                  <div class="pl-2 pr-2">
                    <div class="item-wrapper small">
                      <div class="item-rating">Артикул</div>
                      <div>
                        <small>{{ product.sku }}</small>
                      </div>
                    </div>
                    <div class="item-wrapper small">
                      <div class="item-rating">Цвет</div>
                      <div>
                        <small>{{ product.color_name }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Product Actions -->
              <div class="item-options text-center">
                <router-link
                    class="btn btn-light btn-wishlist"
                    :to="{name: 'dictionary-product-details', params: { id: product.id } }"
                >
                  <feather-icon icon="EyeIcon" class="mr-50"/>
                  <span>Детали</span>
                </router-link>
              </div>
            </div>

          </section>
          <div class="row">
            <div class="col-12" v-if="!loading && products.data.length === 0">
              <div class="card p-2 text-center">Нет товаров</div>
            </div>
          </div>
          <!-- Pagination -->
          <section>
            <div class="row mt-1">
              <div class="col-12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="products.total"
                    :per-page="products.per_page"
                    first-number
                    align="center"
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18"/>
                  </template>
                </b-pagination>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BFormInput, BPagination,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';

export default {
  name: 'Products',
  directives: { Ripple },
  components: {
    // BSV
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BPagination,
    AppBreadcrumb,
  },
  data() {
    return {
      loading: true,
      loadFilters: true,
      currentPage: 1,
      breadcrumbs: [],
      products: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: 0,
        per_page: 32,
      },
      filters: {
        search: null,
      },
    };
  },
  watch: {
    async currentPage(newValue) {
      this.products.current_page = newValue;
      await this.getProducts();
    },
  },
  async mounted() {
    await this.initFilters();
    await this.getProducts();
  },
  methods: {
    async initFilters() {
      const filters = this.$route.query;
      if (filters) {
        this.filters.search = filters.search || null;
      }
    },
    async filter() {
      const query = {
        ...(this.filters.search && { search: this.filters.search }),
      };

      await this.$router.replace({ query }).catch(() => {
      });
      await this.getProducts();
      this.loadFilters = false;
    },
    async getProducts() {
      this.loading = true;
      const filters = { ...this.$route.query };
      filters.limit = 30;

      if (this.products.current_page > 1) {
        filters.page = this.products.current_page;
      }

      this.products = (await this.$api.products.list(filters)).data;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '~@core/scss/vue/libs/vue-slider.scss';

</style>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
  height: 300px;
  overflow-y: scroll;

  ::v-deep {
    .custom-radio, .custom-control-label {
      cursor: pointer;
    }

    .custom-control {
      margin-bottom: 0.75rem;
    }
  }
}

.loading-row {
  height: 200px;
}

.item-img {
  min-height: 150px !important;
}

.grid-view .ecommerce-card.empty {
  height: 200px;
}

.ecommerce-application {
  .grid-view {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1600px) {
  .ecommerce-application {
    .grid-view {
      grid-template-columns:  1fr 1fr 1fr 1fr 1fr;
    }
  }
}

@include media-breakpoint-down(lg) {
  .ecommerce-application {
    .grid-view {
      grid-template-columns:  1fr 1fr 1fr 1fr;
    }
  }
}

@include media-breakpoint-down(md) {
  .ecommerce-application {
    .grid-view {
      grid-template-columns:  1fr 1fr 1fr;
    }
  }
}

@include media-breakpoint-down(xs) {
  .ecommerce-application {
    .grid-view {
      grid-template-columns:  1fr 1fr;
    }
  }
}

.user-product {
  border: 2px solid orange;
}
</style>
